import utils from './utils';
import DateAdditions from './date'; // init. Date


// If console isn't defined then just swallow the console calls
// will prevent breaking IE7
if ( ! window.console) {
  utils.patchConsole();
}

// HACK(digia): Fix the issue with missing $.curCss
if (jQuery && !jQuery.curCss) {
  jQuery.curCSS = (element, prop, val) => jQuery(element).css(prop, val);
}

