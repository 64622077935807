function noop() {}

function patchConsole() {
  const names = [
    'log', 'debug', 'info', 'warn', 'error', 'assert', 'dir',
    'dirxml', 'group', 'groupEnd', 'time', 'timeEnd', 'count', 'trace',
    'profile', 'profileEnd'
  ];

  window.console = {};
  for (let i = 0, l = names.length; i < l; i++) {
    window.console[names[i]] = noop;
  }
}

export default {
  noop,
  patchConsole,
};
